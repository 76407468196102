
/* Main component styles */
.edit-campaign {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
}

.edit-campaign__container {
  display: flex;
  width: 608px;
  max-width: 100%;
  flex-direction: column;
}

.back-button {
  border-radius: 12px;
  align-self: flex-start;
  display: flex;
  min-height: 24px;
  padding: 2px 0 2px 4px;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.back-button__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.campaign-form {
  align-self: flex-end;
  display: flex;
  margin-top: 16px;
  width: 584px;
  max-width: 100%;
  flex-direction: column;
}

.campaign-form__header {
  display: flex;
  align-items: center;
  gap: 45px;
  padding: 50px 0px 50px 0px;
}

.icon-wrapper {
  border-radius: 20px;
  background-color: #f6efff;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 4px;
  align-items: center;
  justify-content: center;
}

.icon-wrapper__image {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.campaign-form__title {
  color: #434343;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.form-group {
  margin-bottom: 28px;
}

.form-label {
  color: #464646;
  font-size: 12px;
  display: block;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #8a8989;
  padding: 11px 16px;
  font-size: 14px;
  color: #434343;
  min-height: 40px;
}

.duration-section {
  margin-top: 64px;
}

.duration-section__header {
  display: flex;
  align-items: center;
  gap: 14px;
}

.duration-section__title {
  color: #434343;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.duration-section__info {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.duration-section__description {
  color: #878787;
  font-size: 12px;
  margin: 0;
}

.duration-section__icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.date-inputs {
  display: flex;
  gap: 32px;
  margin-top: 36px;
}

.date-inputs .form-group {
  flex: 1;
  min-width: 240px;
}

.campaign-form__actions {
  display: flex;
  margin-top: 112px;
  gap: 56px;
  justify-content: flex-end;
}

.button {
  min-height: 40px;
  padding: 9px 24px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  width: 140px;
  cursor: pointer;
}

.button--secondary {
  border: 1px solid #65308f;
  color: #65308f;
  background: transparent;
}

.button--primary {
  border: 1px solid #65308f;
  background-color: #65308f;
  color: #fff;
}

/* Date picker specific styles */
.date-picker-wrapper {
  position: relative;
}

.date-picker-input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #8a8989;
  padding: 11px 16px;
  padding-right: 40px;
  font-size: 14px;
  color: #434343;
  min-height: 40px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="%238a8989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>') no-repeat;
  background-position: right 12px center;
  cursor: pointer;
}

.date-picker-calendar {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 100;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 300px;
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: all 0.2s ease;
}

.date-picker-calendar.visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.calendar-title {
  font-weight: 600;
  color: #434343;
}

.calendar-nav {
  display: flex;
  gap: 8px;
}

.calendar-nav-btn {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #65308f;
  border-radius: 4px;
}

.calendar-grid {
  padding: 12px;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-size: 12px;
  color: #878787;
  margin-bottom: 8px;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.calendar-day {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  border-radius: 4px;
  font-size: 14px;
  color: #434343;
  cursor: pointer;
}

.calendar-day:hover:not(:disabled) {
  background: #f6efff;
}

.calendar-day.selected {
  background: #65308f;
  color: white;
}

.calendar-day.today {
  font-weight: 600;
}

.calendar-day:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.date-error {
  color: #dc2626;
  font-size: 12px;
  margin-top: 4px;
  display: none;
}

.date-error.visible {
  display: block;
}

@media (max-width: 991px) {
  .edit-campaign {
    padding: 42px 20px 100px;
  }

  .campaign-form__actions {
    margin-top: 40px;
  }

  .button {
    padding: 9px 20px;
  }

  .duration-section {
    margin-top: 40px;
  }

  .date-inputs {
    flex-wrap: wrap;
  }
}

.success-dialog {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

img.greentick-img {
  width: 64px;
}

.green-tick {
  display: flex;
  justify-content: center;
}